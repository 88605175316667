/**
 * Created by dek on 25.03.2019.
 */
$(document).ready(function(){
  $('.js-form').on('submit', function(e){
    var form = $(this);
    e.preventDefault();
    var data = new FormData();

    $.each(form.find('[type=file]'), function(k, el) {
      $.each($(el)[0].files, function(i, file) {
        data.append('file-'+i, file);
      });
    });

    $.each(form.find('input'), function(i, inp) {
      var input = $(inp);

      if(input.attr('type')!='file'){
        data.append(input.attr('name'), input.val());
      }
    });

    $.each(form.find('textarea'), function(i, inp) {
      var input = $(inp);
      data.append(input.attr('name'), input.val());
    });

    $.each(form.find('[type=radio]:checked'), function(i, inp) {
      var input = $(inp);
      data.append(input.attr('name'), input.val());
    });

    $.ajax({
      url: form.attr('action'),
      data: data,
      cache: false,
      contentType: false,
      processData: false,
      method: 'POST',
      type: 'POST',
      success: function(data){
        //alert(data);
        if(data.status=='ok'){
          if(data.nSuccess){
            form.html(data.nSuccess);
          }else{
            form.html('<h2>Ваше сообщение отправлено</h2>');
          }
          $('[data-fancybox-close]').click();
        }
      }
    });
    return false;
  });
});
